<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.29289 3.29289C6.68342 2.90237 7.31658 2.90237 7.70711 3.29289L11.7071 7.29289C12.0976 7.68342 12.0976 8.31658 11.7071 8.70711C11.3166 9.09763 10.6834 9.09763 10.2929 8.70711L8 6.41421V20C8 20.5523 7.55228 21 7 21C6.44772 21 6 20.5523 6 20V6.41421L3.70711 8.70711C3.31658 9.09763 2.68342 9.09763 2.29289 8.70711C1.90237 8.31658 1.90237 7.68342 2.29289 7.29289L6.29289 3.29289ZM17 3C17.5523 3 18 3.44772 18 4V17.5858L20.2929 15.2929C20.6834 14.9024 21.3166 14.9024 21.7071 15.2929C22.0976 15.6834 22.0976 16.3166 21.7071 16.7071L17.7071 20.7071C17.3166 21.0976 16.6834 21.0976 16.2929 20.7071L12.2929 16.7071C11.9024 16.3166 11.9024 15.6834 12.2929 15.2929C12.6834 14.9024 13.3166 14.9024 13.7071 15.2929L16 17.5858V4C16 3.44772 16.4477 3 17 3Z"
    />
  </svg>
</template>
